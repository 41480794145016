/*
// .nav-panel
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.nav-panel {
    min-height: 80px;
    background: #ffffff;
    // box-shadow: $nav-panel-shadow;
    color: $nav-panel-font-color;
    // border-bottom: 1px solid #66C3D0;
}
.nav-panel--stuck {
    z-index: 10;
    width: 100%;
    position: fixed;
    top: 0;
    // box-shadow: 0 1px rgb(0 0 0 / 4%), inset 0 -1px rgb(0 0 0 / 4%);;
}
.nav-panel__container {
    height: 100%;
}
.nav-panel__container.container_fm {
    .nav-panel__row {
        display: grid;
        
        
        justify-items: flex-start;
        align-items: center;
        
        position: relative;
        min-height: 80px;
        height: 100%;

        grid-template-columns: 200px 1fr 120px;
        
        &.active {
            grid-template-columns: 200px 1fr 180px !important;
        }

        & > .nav-panel__indicators > .nav-count_fm {
            display: grid;
            grid-gap: 12px;
           
            grid-template-columns: 1fr 1fr 1fr;
            &.active {
                grid-template-columns: 1fr 1fr 1fr 1fr !important;
            }

            & > .indicator {
                margin: 0 !important;
            }
            & > .fm-last_cart > .indicator {
                margin: 0 !important;
            }
        }
    }


    
}
.nav-panel__logo svg {
    display: block;
    fill: $nav-panel-logo-color;
}
.nav-panel__departments {
    flex-shrink: 0;
}
.nav-panel__logo + .nav-panel__nav-links,
.nav-panel__departments + .nav-panel__nav-links {
    @include direction {
        #{$margin-inline-start}: 18px;
    }
}
.nav-panel__nav-links {
    @include direction {
        #{$margin-inline-end}: 18px;
    }
}
.nav-panel__indicators {
    position: relative;
    display: flex;
    height: 100%;

    // @include direction {
    //     #{$margin-inline-start}: auto;
    // }
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .nav-panel__nav-links {
        @include direction {
            #{$margin-inline-start}: 14px;
            #{$margin-inline-end}: 14px;
        }
    }
}

@media (max-width: breakpoint(md-end)) {
    .nav-panel__row {
        grid-template-columns: 1fr 90px !important;
    }
}

