/*
// .filter-color
*/
@import "../variables";

$local-margin: 4px;

.filter-color {
}
.filter-color__list {
    padding: 2px 0;
    display: flex;
    flex-wrap: wrap;
    margin: -$local-margin;
}
.filter-color__item {
    cursor: pointer;
    margin: $local-margin;
}
.filter-color__check {
    display: block;
    border: 1px solid silver;
    border-radius: 5px;
    padding: 1px;
}
