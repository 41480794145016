/*
// .site
*/
@import "../variables";

.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
    &.desktop {
        position: sticky;
        top: 0;
        z-index: 5;
    }
}
.site__body {
    flex-grow: 1;
    // padding-top: 29px;
    margin-bottom: 100px;
    margin-top: 10px;
}
.site__footer {
    flex-shrink: 0;
}
@media (max-width: breakpoint(sm-end)) {
    .site__header {
        &.desktop {
            position: static;
        }
    }
}
