// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@import "~video-react/styles/scss/video-react";
@font-face {
    font-family: "Mardoto";
    src: url("../fonts/Mardoto-Regular.woff2") format("woff2");
}

body * {
    font-family: "Mardoto";
}

.br_fm {
    border-left: 1px solid #c2c2c2;
}

.container_fm {
    max-width: 1400px;
    padding: 0 12px;
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

.absolute {
    position: absolute !important;
}

.departments__links {
    color: black;
    background: #f3f3f3;
}

.site-header__search {
    display: flex;
    justify-content: flex-end;
    // margin-left: 40px;
}

.search__button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.search__input {
    border-radius: 0;
    border-style: none;
    padding: 0;
    width: 1px;
    flex-grow: 1;
    font-size: 16px;
    transition: color 0.15s;
    color: #8f8f8f;
}

.indicator__value {
    color: #fff;
}

.block-features__list {
    border: none;
}

.block-header {
    justify-content: center;
}

.block-header__title__custom_fm {
    margin-bottom: 30px;
    font-size: 48px;
    color: #060608;
    margin-top: 70px;
    text-align: center;
}

.checkout__totals-subtotals .price_fms,
.checkout__totals-footer .price_fms {
    margin-right: 6px;
}

.block-header__title__custom {
    display: flex;
    justify-content: center;
    align-items: center;
    &::after,
    &:before {
        content: "";
        display: block;
        width: 66px;
        height: 4px;
        background-color: #333333;
    }
    &::after {
        margin-left: 15px;
    }
    &:before {
        margin-right: 15px;
    }
}

.brands {
    padding: 50px 0;
}

.site-footer__widget > div {
    justify-content: start !important;
}

.footer-newsletter__form-button {
    max-width: 253px;
    border-radius: 8px;
    margin-top: 15px;
    color: #fff;
    background-color: #0d47a1;
    border-color: #0d47a1;
}

.footer-links__list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e4e4e4;
    margin: 2px 0;
    padding: 5px;
}

.footer_custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer_custom_wrap {
    align-self: flex-start;
}

.footer-links__list ul li:first-child {
    justify-content: center !important;
}

.footer_day,
.footer_calc {
    justify-content: start !important;
    color: #0d47a1;
}

.footer_day span,
.footer_calc span {
    margin-left: 5px;
}

.block-slideshow__slide-content {
    display: flex;
    flex-direction: column;
    margin-right: 31rem;
}

.block-slideshow__slide .container {
    position: relative;
    flex-direction: column;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
}

.block-header__title__custom {
    font-weight: 400;
}

.btn-primary {
    border-radius: 20px;
}

.block-slideshow__slide-button .btn-primary {
    border-radius: 22px;
}

.address-card__badge {
    color: #000;
    background: #e4e4e4;
    border: 1px solid silver;
}

.departments__links {
    visibility: hidden;
}

// .nav-panel__departments .departments--opened .departments__links {
//     visibility: visible;
// }

/***Shop***/

.view-options__control {
    max-width: 166px;
    width: 100%;
}

#view-options-limit,
#view-options-sort {
    background-position: right 1.5rem center;
    background-size: initial;
    width: 100%;
    border-radius: 21px;
    text-align-last: center;
}

.input-range__track--active {
    background: #ededee;
}

.input-range__slider:after,
.input-range__slider {
    background: #ededee;
}

.input-range__track {
    background: #ededee;
    height: 4px;
}

.filter-price__title {
    display: flex;
    justify-content: space-between;
    .filter-price__min-value,
    .filter-price__max-value {
        background-color: #428bca;
        padding: 0.1rem;
        color: #fff;
    }
}

.input-check-color__box {
    border-radius: 50%;
}

.input-radio__input:checked ~ .input-radio__circle::after {
    background: #fff;
}

.page-item.active .page-link {
    color: #fff;
}

.input-number__input {
    border: none;
    border-bottom: 1px solid silver;
}

.input-number__sub,
.input-number__add {
    width: 35px;
}

.input-number__add::before {
    width: 13px;
    height: 2px;
    background: #2d2d33;
}

.input-number__add::after {
    width: 2px;
    height: 13px;
    background: #2d2d33;
}

.posts-list--layout--grid .posts-list__item {
    width: 30%;
}

.product-image__img {
    position: relative;
}

.roduct-image__img_fm {
    position: relative;
    height: 256px;
}

.product-image__body {
    padding-bottom: initial;
    // height: 300px;
}

.btn-secondary {
    border-radius: 20px;
}

.page-header {
    background-color: #e4e4e4;
    margin-bottom: 30px;
    position: relative;
    margin-top: 16px;
}

.page-header__container {
    padding: 10px;
}

.block-slideshow--layout--full {
    margin-top: 0;
}

.menu__icon img {
    margin-top: 6px;
    width: 24px;
    height: 16px;
}

.line {
    width: 100%;
    height: 0.2px;
    background-color: #333333;
}

.alert-danger {
    background: none;
    color: red;
    font-size: 14px;
}

.mobile-links--level--0 > li > .mobile-links__item > .mobile-links__item-title {
    height: 60px;
}

.mobile-links__item .mobile-links__item-link {
    padding: 8px 20px;
    line-height: 21px;
}

.mobile-links__item-title .mobile-links__item-link {
    font-size: 15px;
    padding: 7px 20px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.Toastify__toast-container {
    max-width: 500px;
    width: 100%;
}

.Toastify__toast-body:before {
    // content: url('../svg/done.svg');
    content: "";
    display: block;
    margin-right: 15px;
}

.Toastify__close-button {
    color: #333;
}

.Toastify__toast--success {
    background: #fff;
    color: #333;
}

.Toastify__toast-body {
    padding: 10px 0;
    display: flex;
    text-align: justify;
    align-items: center;
}

.product_attributes {
    .product__meta {
        font-size: 16px;
        flex-direction: column;
        margin-bottom: 2px;
        li {
            max-width: 250px;
            justify-content: space-between;
            align-items: flex-start;
            display: flex;
        }
    }
}

.block-slideshow__slide-content {
    margin-right: 31rem;
    justify-content: center;
    align-items: center;
    display: flex;
    align-items: flex-end;
}

.departments__links > li > a,
.departments__menu li {
}

.block_slide_fm {
    display: flex;
    align-items: center;
}

.block-slideshow--layout--full .block-slideshow__slide-title {
    @media (max-width: 1040px) {
        margin-left: initial;
    }
    margin-left: 70px;
}

.document {
    border: 2px solid #f0f0f0;
    border-radius: 2px;
    padding: 85px 200px 120px;
    position: relative;
    top: -125px;
    background: white;
}

.page-header__title > h1 {
    font-size: 28px;
}

.dashboard__address > h2 {
    font-size: 24px;
    padding: 10px;
}

.product_fms {
    max-width: 265px;
    // min-height: 413px;
    width: 100%;
    // margin-bottom: 30px;
    // margin-right: auto;
}
.product_fms:nth-child(5) {
    margin-right: 0 !important;
}
.product_fms:nth-child(10) {
    margin-right: 0 !important;
}

.products-view__list .product_fms {
    // max-width: 242px;
    // margin-bottom: 20px;
}

.product-card__actions_fms {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // flex-direction: column;
    &:last-child {
        margin-top: auto;
        margin-bottom: 10px;
    }
}

.product-card__addtocart_fms {
    width: 42px;
    height: 40px;
    border: none;
    background: #f83641;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.product-image {
    position: relative;
}

.wish_fms {
    display: none;
    position: absolute;
    z-index: 5;
    padding: 32px 0px;
    height: 100%;
}

.in_stock_fms {
    width: 60px;
    height: 24px;
    background: #6ce348;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
}

.wish_and_fms {
    margin-left: 20px;
}

.footer_contact_text_fms {
    font-size: 16px;
    color: white;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.del_fms {
    max-width: 236px;
}

.foot_content_fms {
    display: flex;
    justify-content: space-between;
}

.foot_content_fms > div {
    margin: 20px 0;
}

.foot_content_fms > div:first-child {
    margin-left: 0;
}

.foot_content_fms > div:last-child {
    margin-right: 0;
}

.cart_bank_fms {
    margin-bottom: 10px;
    text-decoration: underline;
}

.l-h {
    line-height: 30px;
}

.foot_bottom_fms {
    background-color: #66c3d0;
    height: 43px;
}

.foot_bottom_fms > div {
    background-color: #66c3d0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.foot_bottom_fms span,
.foot_bottom_fms a {
    color: #fff !important;
    font-size: 16px;
}

.partner_fms {
    position: relative;
    display: block;
}

.slider_button_fms {
    position: absolute;
    width: auto;
    top: 55%;
    transform: translateY(-50%);
    // display: flex;
    justify-content: space-between;
    z-index: 10;
    padding: 0 14px;
    display: none;
    transition: 100ms;
}
.slider_button_fms.fm_next {
    right: 0;
}

.partner_fms:hover {
    .slider_button_fms {
        display: flex;
    }
}

.menu_cat_fms {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 40;
    display: flex;

    .cat_list_fms {
        position: relative;
        max-width: 350px;
        height: 100%;
        background-color: #fff;
        padding: 20px 0 0 20px;
        width: 100%;
    }

    .site-header__logo {
        margin-bottom: 36px;
    }

    h3 {
        color: #060608;
        font-size: 22px;
        font-weight: normal;
        margin-bottom: 16px;
    }
}

.cat_list_item_fms > div {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    padding-right: 5px;
    flex-direction: column;
}

.shop-castalog_fms {
    text-align: center;
    width: 100%;
}

@media (max-width: breakpoint(md-extra-size)) {
    .mobile-header__indicators {
        div.indicator {
            margin-bottom: 13px !important;
        }
        div.topbar-dropdown {
            margin-bottom: 8px !important;
        }
    }
}

@media (max-width: breakpoint(sm-end)) {
    .mobile-header__indicators {
        div.topbar-dropdown {
            margin-bottom: 11px !important;
        }
    }
}

.cat_list_item_fms {
    height: 25px;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    flex-flow: row wrap;
    align-items: center;
    white-space: nowrap;
    // overflow: auto;
}
.cat_list_item_fms > div svg {
    margin-left: auto;
    // margin-bottom: 20px;
}

.cat_list_item_fms > div span {
    color: #000000;
    font-size: 14px;
    margin-left: 12px;
    margin-right: 10px;
}

.sub_fms {
    max-width: 258px;
    display: flex;
}

.close_fm {
    position: absolute;
    right: 13px;
    top: 30px;
    cursor: pointer;
}

.subcat_list_fms {
    margin-bottom: 22px;
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // height: 100%;
    // left: 350px;
    background: #fff;
    // padding: 20px;
    display: none;
    z-index: 2;
    background: #f3f3f3;
    border: solid 1px rgba(36, 32, 32, 0.2);
    min-width: 178px;
    position: absolute;
    top: 66%;
}

.subcat-sub_list_fms {
    // position: fixed;
    // top: 90px;
    // left: 1115px;
    background: black;
    // padding: 20px;
    display: none;
    min-width: 210px;
    border: solid 1px silver;
    background-color: #f3f3f3;
    position: absolute;
    left: 176px;
    top: -1px;
}

.db_block {
    display: block !important;
}

.subcat_list_item_fms > div span {
    color: #000000;
    font-size: 14px;
    margin-left: 12px;
    margin-right: 10px;
    // margin-bottom: 20px;
}

.subcat_list_item_fms > div {
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

// .subs_fms > div{
//     margin-right: 0;
// }

.subcat_list_item_fms > div:hover {
    background: #e4e4e4;
    position: relative;
}

// .pad-top-cat_fm {
//     padding-top: 126px !important;
// }

.nav_modal_fm {
    max-width: 400px;
    .quickview {
        padding: 30px;
    }
    .login_content_fm {
        max-width: 415px;
        margin: 0 auto;
        text-align: center;
        .sh_fm {
            margin-bottom: 16px;
        }
        .logn_title_fm {
            font-size: 30px;
            color: #000000;
            text-align: center;
            font-weight: 400;
            margin-bottom: 10px;
        }
        form {
            margin-bottom: 10px;
            div {
                position: relative;
                margin-bottom: 10px;
                label {
                    width: 100%;

                    span {
                        font-size: 18px;
                        color: #000000;
                    }
                }
                .login_inp_fm {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #cccccc;
                    padding-left: 12px;
                    border-radius: 4px;
                }
                .login_inp_fm:active,
                .login_inp_fm:focus {
                    border: 1px solid #2d2d2d;
                    outline: none;
                }
            }
            .forgot_pass_fm {
                color: #959595;
                text-align: right;
                font-size: 14px;
                span {
                    cursor: pointer;
                }
            }
            .rem_fm {
                text-align: left;
                margin-bottom: 16px;
                input {
                    border: 1px solid #b8b8b8;
                    margin-right: 8px;
                }
                span {
                    font-size: 14px;
                    color: #959595;
                }
            }
            button {
                max-width: 162px;
                width: 100%;
                margin: 0 auto;
                height: 40px;
                font-size: 16px;
                color: #ffffff;
                font-weight: 400;
                border: none;
                background: #f32c37;
                border-radius: 50px;
            }
        }
        .sign_up_fm {
            .signup_title_fm {
                font-size: 14px;
                color: #959595;
                margin-bottom: 16px;
            }
            .social_reg_fm {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                .fb_reg_fm {
                    width: 100%;
                    height: 44px;
                    border: none;
                    background: #5a80d5;
                    border-radius: 4px;
                }
                .g_reg_fm {
                    width: 200px;
                    height: 44px;
                    border: none;
                    background: #de6152;
                    border-radius: 4px;
                }
            }
            .singup_link_fm {
                font-size: 14px;
                color: #000000;
                font-weight: 400;
                cursor: pointer;
                span {
                    color: #43b02a;
                }
            }
        }
    }
}

.border_red {
    border: 1px solid red !important;
}

.cash_title {
    font-size: 18px;
    color: #000000;
}

.add_cart_fms:focus {
    outline: none;
    background: #f32c37;
    color: white;
}

button:focus {
    outline: none;
}

.add_cart_fms {
    padding: 10px 33px;
    height: 40px;
    border: none;
    background: #f32c37;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 50px;
    width: 200px;

    &:disabled{
        opacity: .4;
        cursor: default;
        pointer-events: none;
    }
}

.add-wish-fms {
    padding: 10px 2px;
    background: transparent;
    border: none;
}

.add-wish-fms:focus,
.add-wish-fms:active {
    border: none;
    outline: none;
}

.add-wish-fms:active {
    svg {
        path {
            fill: red;
        }
    }
}

.add_cart_fms svg {
    margin-right: 8px;
}

.text-success_fms {
    color: #158893;
    font-size: 18px;
    padding-top: 16px;
    font-weight: normal;
    margin-right: 10px;
}

.sku_fms {
    font-size: 16px;
    font-weight: normal;
    color: #000000;
}

.title_account_fms {
    text-align: center;
    font-size: 28px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 50px;
}

.account-nav {
    border: none;
}

.account-nav__item {
    min-height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 0 20px;
}

.account-nav__item div {
    display: flex;
    align-items: center;
}

.account-nav__item--active {
    a {
        color: #157dff;
    }
    svg {
        fill: #157dff;
    }
    #Icon_feather-list,
    #Icon_feather-log-out,
    #Location {
        path {
            stroke: #157dff;
        }
    }
    #user-2 {
        path {
            fill: #157dff;
        }
    }
}

.account-nav__item a {
    font-size: 16px;
}

.account-nav__item a:hover {
    background: transparent;
}

.save_profile_data_fm {
    border: none;
    padding: 10px 73px;
    background: #f32c37;
    color: #ffffff;
    font-size: 18px;
    margin-left: auto;
    border-radius: 50px;
}

.profile-label-fm {
    color: #000000;
    font-size: 15px;
}

.add_address_fm {
    color: #000000;
    font-size: 12px;
}

.address-title-fm {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #d7d3d3;
    font-size: 18px;
    color: #000000;
}

.address-card__footer a {
    font-size: 12px;
    color: #686868 !important;
}

.address-card__footer a:last-child {
    color: red !important;
}

.cart_block_fm {
    padding: 30px 50px;
}

.cart_info_fm {
    display: flex;
    align-items: center;
}

.cart_mob_title_fm {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart_mob_prod_fm {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        margin-right: 10px;
    }
}

.cart_mob_title_fm > div {
    padding: 8px 0;
    font-size: 12px;
    color: #2d2d2d;
}

.cart_mob_blok_fm {
    border-bottom: 1px solid #f5f5f5;
}

.btn_cart_rem_fm {
    background: transparent;
    border: none;
    outline: none;
}

.btn_cart_rem_fm svg {
    width: 20px;
    height: 20px;
    fill: black;
}

.btn_cart_rem_fm path {
    fill: #e4e4e4;
}

.btn_cart_rem_fm:focus {
    outline: none;
    border: none;
}

.cart-table__column--price {
    text-align: center !important;
}

.cart-table__head .cart-table__column {
    font-size: 22px;
    color: #000000;
    font-weight: 500;
}

.cart-table__body {
    font-size: 20px;
    color: #000000;
}

.cart-table__column--image {
    width: 35%;
}

.cart-table__column--image img {
    width: 120px;
}

.cart_fms {
    padding-top: 21px;
    margin-bottom: 100px;
}

.cart-table__product-name {
    white-space: nowrap;
    width: 100%;
    max-width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cart__update-button {
    padding: 13px 50px;
    background: transparent;
    border: 1px solid #f83641;
    font-size: 14px;
    color: #000000;
    border-radius: 50px;
}

.cart__checkout-button {
    padding: 13px 50px;
    background: #f83641;
    border: none;
    font-size: 14px;
    color: #fff;
    border-radius: 50px;
}

.cart__checkout-button:hover {
    color: #fff;
}

.cart__totals-footer {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    color: #000000;
    margin-top: 48px;
}

.cart__totals-footer div:last-child {
    font-weight: 500;
    margin-left: 30px;
}

.cart-table__body .cart-table__row:last-child .cart-table__column {
    border-bottom: none;
}

.cart_go_back_fm {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #686868;
    margin-top: 30px;
    cursor: pointer;
}

.cart_go_back_fm svg {
    margin-right: 11px;
}

.view-options__control {
    margin: 0 !important;
}

.view-options-sort_fm {
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #414146;
    cursor: pointer;
    display: flex;
    align-items: start;
}
.view-options-sort {
    display: none;
}

.view-options-sort_fm span:first-child {
    color: #060608;
    // font-weight: 500;
}

.view-options-sort_fm span {
    margin-right: 10px;
}

.page-item .page-link {
    background: transparent;
    color: #000000;
    font-size: 16px;
}

.page-item.active .page-link {
    background: transparent;
    color: #f83641;
    font-size: 16px;
}

.savings_fms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    label {
        width: 100%;
    }
}

.filter-list__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.widget__title {
    font-size: 18px;
    color: #060608;
    // padding-bottom: 11px;
    // border-bottom: 1px solid #e4e4e4;
    margin-bottom: 11px;
    font-weight: 500;
}

.widget-filters {
    padding: 10px;
    border: 1px solid #e4e4e4;
}

.filter-list__title {
    margin-bottom: 0 !important;
    color: #060608;
    margin-bottom: 16px;
}

.filter-list__title:last-child {
    margin-bottom: 0;
}

.filter-list__item {
    margin: 0 10px 0 0;
    margin-bottom: 17px;
}

.filter-price__title span {
    font-size: 16px;
    color: #060608 !important;
    background: transparent !important;
}

.input-range__track--active {
    background: #fec62e;
}

.widget-filters__actions {
    display: flex;
    justify-content: flex-end;
}

.widget-filters__actions button {
    width: 100%;
    height: 40px;
    border: none;
    background: #f0f0f0;
    border-radius: 50px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.widget-filters__actions button:hover {
    opacity: 0.3;
}

.filter-price__slider {
    padding: 0 5px;
}

.wishlist {
    display: flex;
    flex-wrap: wrap;
}

.wishlist > div {
    max-width: 270px;
    width: 100%;
    margin: 0 1%;
    margin-bottom: 16px;
}

.wishlist .product-card {
    width: 100%;
    min-height: 413px;
}

.product-gallery__featured .product-image__body {
    height: 420px;
    padding-bottom: 10px !important;
}

.remove-wish_fm span {
    font-size: 14px;
    color: #949494;
    margin-right: 6px;
}

.remove-wish_fm {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
}

.product-card {
    padding: 8px;
    .product-card__new-price {
        color: red !important;
    }
}

.product-card__image {
    padding: 0;
}

.product-card__info {
    margin-top: 20px;
    padding: 0;
    min-height: 50px;
}

.product-card__wishlist {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    width: unset !important;
}

.filter-color__list {
    display: flex;
}

.input-check-color__box {
    border-radius: 4px !important;
}

.check_butt_fms {
    border-radius: 50px;
    padding: 11px 46px;
    border: none;
    background: #f83641;
    color: white;
}

.nav-count_fm {
    position: relative;
}

.nav-count-cont_fm {
    position: absolute;
    height: 18px;
    width: 18px;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f83641;
    right: 24px;
}

.search-product_fm {
    padding: 36px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .product_fms {
        margin-bottom: 20px;
    }
}

.search-product_fm .product-card {
    margin-bottom: 16px;
}

.price_fms {
    font-weight: 500;
    font-size: 22px;
}

.product__prices .price_fms {
    color: #000000;
    font-weight: 500;
}

.product__meta {
    margin-bottom: 26px;
}

.product__meta > div {
    font-size: 18px;
    color: #000000;
    // margin-bottom: 14px;
}

// .other-attr_fm{
//     margin-bottom: 22px !important;
// }

// .product__meta > div> div{
//     margin-bottom: 14px;
// }

.color-attr_fm,
.other-attr_fm {
    display: flex;
}

.other-attr_fm .other-attr_fm {
    margin: 0;
}

.other-radio__input {
    opacity: 0;
    position: absolute;
}

.other-list__item {
    padding: 3px 5px;
    cursor: pointer;
    border: 1px solid #929292;
    border-radius: 4px;
    margin-right: 18px;
}

.other-list__title {
    font-size: 14px;
    color: #000000;
}

.attributes_fm {
    margin: 50px 0;
    border: 1px solid #e4e4e4;
}

.attr-content_fm {
    margin: 0 30px;
    padding: 15px 0;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
}

.attr-content_fm div:first-child {
    width: 30%;
}

// .attr-title_fm{
//     padding: 15px 30px;
//     background: #E4E4E4;
//     font-size: 20px;
//     color: #000000;
//     font-weight: 500;

// }

.attr-border-fm {
    border-color: #f42c37 !important;
}

.errMSG {
    color: red;
    display: none;
}

.compare_fm {
    border: 1px solid #e4e4e4;
    width: 73%;
    border-radius: 10px;
}

.title_compare {
    font-size: 28px;
    color: #000000;
    margin: 30px auto 50px;
    text-align: center;
}

.compare_fm .img-model_fm {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 47px 0;
    border-bottom: 1px solid #eeeeee;
}

.compare_fm .img-model_fm > div {
    width: 28%;
    text-align: center;
    position: relative;
    height: 100%;
}

.compare_fm .img-model_fm > div:first-child {
    width: 16%;
}

.compare_fm .compare-table__product-name {
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
}

.attr-title_fm {
    padding: 8px 20px;
    // border-top: 5px solid #EEEEEE;
    // border-bottom: 1px solid #EEEEEE;
    font-size: 16px;
    background: #eeeeee;
    color: #191919;
    font-weight: bold;
}

.attr-information_fm {
    border-bottom: 1px solid #eeeeee;
}

.attr-information_fm {
    display: flex;
    // justify-content: space-between;
}

.attr-information_fm > div {
    width: 28%;

    padding: 8px 20px;
    font-size: 14px;
    color: #000000;
    &:last-child {
        border-bottom: none !important;
    }
}

.attr-information_fm > div:first-child {
    width: 16%;
}

.attr-information_fm > div:first-child {
    font-size: 14px;
    color: #676674;
    border-left: none;
    border-right: none;
}

.remove-compare_fm {
    background: transparent;
    border: none;
    position: absolute;
    right: 30px;
    top: 0;
    z-index: 10;
}

.product-card__info {
    padding: 0 !important;
}

.address-card__row {
    display: flex;
    justify-content: space-between;
}

.card-body .form-control::placeholder {
    font-size: 16px;
    color: #b1b0b0;
}

.checkout__totals th {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
}

.checkout__totals th,
.checkout__totals td {
    font-size: 16px;
    color: #000000;
    line-height: 23px;
    padding-bottom: 16px;
}

.price_fms {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding-right: 5px;
}

.cart__totals-footer .price_fms {
    font-size: 20px;
}

.checkout__totals-footer span {
    font-size: 18px;
    color: #f83641;
    font-weight: 400;
}

.prod_fms .attr-information_fm > div:first-child {
    width: 30%;
}

.prod_fms .attr-information_fm > div:last-child {
    width: 80%;
}

.prod_fms {
    margin-top: 50px;
}

.product-gallery__featured {
    box-shadow: none;
}

.product--layout--standard {
    border: 1px solid #e4e4e4;
    padding-left: 100px;
    padding-bottom: 50px;
    padding-top: 50px;
    width: 73%;
}
.product--layout-second-section {
    width: 26%;
    border: 1px solid #e4e4e4;
}

.product__prices > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    max-width: 172px;
    border: 2px solid #d7d7d7;
    border-radius: 7px;
    margin-bottom: 10px;
    padding: 10px 10px;
    position: relative;
    justify-content: space-between;
}
.d-none-button {
    display: none;
}
.departments {
    display: flex;
    align-items: center;
}
.site-header__logo,
.searchss {
    display: block;
}
@media only screen and (max-width: 540px) {
    .category-hp-body {
        .category-hp-titles p:last-child {
            font-size: 20px !important;
        }
    }
    .com-border-fms {
        .text-right {
            text-align: center !important;
        }
    }
}

@media only screen and (max-width: 416px) {
    .modal-title {
        font-size: 16px !important;
    }
    .first-fms {
        flex-wrap: wrap;
        .category-hp-body:first-child {
            width: 43% !important;
        }
        .category-hp-body:nth-child(2) {
            width: 42.5% !important;
        }
        .category-hp-body:last-child {
            width: 100%;
        }
    }

    .second-fms {
        flex-wrap: wrap;
        .category-hp-body:first-child {
            width: 100%;
        }
        .category-hp-body:nth-child(2) {
            width: 43% !important;
        }
        .category-hp-body:last-child {
            width: 42.5% !important;
        }
    }
    .category-hp-titles {
        padding-left: 10px !important;
    }
    .category-hp-body {
        .category-hp-titles p:last-child {
            font-size: 16px !important;
        }
    }
    .category-hp-body {
        .category-hp-titles p:nth-child(2) {
            font-size: 14px !important;
        }
    }
}
@media only screen and (max-width: 479px) {
    .product-card__name {
        font-size: 16px;
    }
}

.bottom-part-ofBanner {
    img {
        height: 50%;
    }
}
@media only screen and (max-width: 510px) {
    .site-header__search {
        display: flex !important;
        top: 5px !important;
        right: 80% !important;
        width: 170px;
    }

    .wishlist .product-card {
        min-height: 400px !important;
    }
    .compare_fm .img-model_fm {
        align-items: start;
    }
    .search--location--header .search__suggestions {
        width: calc(150%);
    }
    .bottom-part-ofBanner {
        img {
            height: 310px !important;
        }
    }
    .first-block-banner-titles {
        font-size: 40px !important;
    }
}
@media only screen and (max-width: 991px) {
    .modal-title {
        text-align: center;
        padding: 0 20px 40px 20px !important;
        font-size: 20px !important;
        svg {
            width: 22%;
            margin-right: unset !important;
            margin-bottom: auto !important;
        }
    }
    .block-banks-carousel__cell:hover {
        .block-banks-rate {
            span {
                justify-content: center !important;
            }
        }
    }
    .btn-svg-icon svg {
        top: 10px !important;
    }
    .wish_and_fms {
        margin-right: 8px;
        margin-left: 0;
    }
    .modal-string {
        font-size: 18px !important;
        margin: 30px 0 !important;
        padding: 0 20px;
        text-align: center;
    }

    .modal-content {
        width: 90vh;
        padding: 20px !important;
        margin: 0 20px !important;
    }
    .prod_fms {
        margin-top: unset;
    }
    .product--layout-second-section {
        margin: 30px 0;
    }
    .product--layout-second-section {
        position: unset !important;
        right: unset !important;
    }

    .bt-card-fms {
        margin-left: unset !important;
    }
    .search-product_fm {
        .product-card__addtocart_fms {
            margin-right: auto;
        }
    }
    .bt-card-fms {
        margin-right: auto !important;
    }
    .product-card__buttons > *:first-child {
        margin-right: unset !important;
    }
    .product-card__buttons:hover {
        background: #f83641 !important;
    }

    .block-banks-rate {
        box-shadow: none !important;
    }

    .wish-list-count {
        top: -13px !important;
    }
    .ceeping-line-header {
        margin-bottom: 0 !important;
    }
    .mobile-header__body {
        align-items: end !important;
    }
    .search-button-icon {
        padding-bottom: 0px !important;
    }
    .indicator-log-in {
        min-height: unset !important;
    }

    .nav-count_fm {
        align-items: end !important;
    }
    .product__actions-item-fms {
        align-items: center;
    }
    .product__actions-item--compare {
        padding-top: 0 !important;
    }
    .title-button {
        flex-direction: column;
    }

    .products-list__body {
        .product-card__buttons > *:first-child {
            // margin: 0 auto !important;
            font-size: 16px !important;
        }
    }
    .product-card__actions_fms {
        flex-direction: column !important;
    }
    .site-header__search {
        display: flex !important;
        top: 5px !important;
        right: 76%;
    }

    .fm_cart_compare-fms {
        left: 54% !important;
    }
    .search-button-icon {
        padding-right: 0 !important;

        margin-left: auto !important;
        margin-right: 20px !important;
    }
    .indicator.indicator--trigger--click {
        margin-right: 20px !important;
    }
    .nav-panel__row {
        display: flex !important;
    }
    .fm-last_cart {
        justify-content: unset !important;
        .indicator--trigger--click {
            margin-right: 0 !important;
        }
    }
    .nav-panel__indicators {
        margin-left: auto;
    }
    .departments__button {
        width: 50% !important;
    }
    .first-img-fms {
        height: 460px !important;
    }
    .bottom-part-ofBanner {
        .first-block-banner-titles {
            margin-top: 65px !important;
        }
        .bottom-part-ofBanner img {
            height: 404px;
        }
        .first-block-banner-content {
            p:nth-child(2) {
                font-size: 20px;
            }
            p:nth-child(3) {
                font-size: 28px;
            }
        }
    }
    .product-card__prices {
        margin-bottom: 10px;
    }
    .add-cart-button-fms {
        margin-left: auto;
    }
    .block-products-carousel__column {
        .product-image__img {
            object-fit: unset !important;
        }
    }
    .block-banks-carousel__cell {
        flex-direction: column-reverse !important;
    }
    .block-banks-rate {
        span {
            display: flex !important;
            padding-bottom: 10px;
            justify-content: center;
        }
    }

    .product-card__buttons {
        display: flex;
        position: relative;
        width: 100% !important;
        background: #f83641;
        border-radius: 50px;
        cursor: pointer;
    }

    .add-cart-button-fms {
        display: block !important;
        color: white;
        padding-left: 10px;
    }

    .block-header__title__custom_fm {
        font-size: 26px !important;
    }

    .block-products-carousel__cell {
        justify-content: center;
    }
    .topbar-dropdown__body {
        right: 0;
    }
    .inner-page-fms {
        flex-direction: column;
        .product--layout--standard,
        .product--layout-second-section {
            width: 100%;
        }
    }

    .compare_fm {
        width: 100%;
    }
    .info-buttnos {
        width: 100% !important;
    }
    .first-fms {
        flex-wrap: wrap;
        .category-hp-body:first-child {
            width: 45%;
        }
        .category-hp-body:nth-child(2) {
            width: 45%;
            .category-hp-titles {
                p:last-child {
                    color: yellow !important;
                }
            }
        }
        .category-hp-body:last-child {
            width: 100%;
        }
    }
    .second-fms {
        flex-wrap: wrap;
        .category-hp-body:first-child {
            width: 100%;
            p:last-child {
                color: white;
                opacity: unset;
            }
        }
        .category-hp-body:nth-child(2) {
            width: 45%;
        }
        .category-hp-body:last-child {
            width: 45%;
        }
    }

    .first-block-banner {
        position: unset !important;
        min-height: unset !important;
        img {
            position: static !important;
        }
    }
    .first-img-fms {
        height: 530px;
        position: static;
        object-fit: contain;
    }
    .bottom-part-ofBanner {
        position: unset;
        img {
            height: 340px;
        }
    }

    .first-bann-fms {
        top: -80%;
        height: max-content;
        position: absolute !important;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .first-block-banner-fms {
        min-height: unset !important;
    }

    .first-block-banner-title {
        flex-direction: column !important;
        object-fit: contain !important;
    }

    .first-block-banner-titles {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 25px !important;
    }
    .first-block-banner-content {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .d-none-button {
        display: block;
    }
    .departments,
    // .searchss,
    .site-header__logo {
        display: none;
    }
    .product__prices {
        display: flex;
        flex-direction: column !important;
    }
    .product__prices > .ml-4 {
        margin-top: 1.5rem !important;
        margin-left: 0 !important;
    }
    .mobile-header__logo {
        margin-left: 0 !important;
        margin-top: 12px !important;
    }
    .search--location--mobile-header .search__input {
        font-size: 20px;
    }
    .payment-methods__item-header {
        padding: 0 !important;
        margin-bottom: 10px;
    }
    .mobile-header__logo {
        margin-top: 0 !important;
    }
    .page-header__title > h1 {
        font-weight: normal;
        font-size: 23px;
    }
    .card-title {
        font-size: 18px;
    }
    .product_fms {
        // max-width: 248px;
        max-width: 100% !important;
    }
    .view-options-sort_fm {
        font-size: 15px !important;
    }
    .product-card__new-price .price_fms {
        font-size: 16px;
    }
    .product-card__old-price .price_fms {
        font-size: 16px;
    }
}
@media (max-width: breakpoint(sm-end)) {
    // .products-view__list .product_fms {
    //     max-width: 48%;
    // }
    .product-card__new-price .price_fms {
        font-size: 16px;
    }
    .product-card__old-price .price_fms {
        font-size: 16px;
    }
    .payment-methods__item-header {
        padding: 0 !important;
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 5px;
    }
    .mobile-header__logo {
        margin-top: 0 !important;
    }
    .page-header__title > h1 {
        font-weight: normal;
        font-size: 23px;
    }
    .card-title {
        font-size: 18px;
    }
    .product__prices .price_fms {
        font-size: 16px;
    }
    .mobile-header__search {
        height: 70px;
    }
    .slider_button_fms.banner-slider-fms {
        display: none;
    }
    .address-card__name {
        display: flex;
        width: 300px;
    }
    .address-card__row {
        width: 300px;
        display: flex;
        justify-content: start;
    }
    .address-card__footer {
        display: flex;
    }
    .addresses-list__plus::before,
    .addresses-list__plus::after {
        left: calc(30% - 4px) !important;
    }

    .slider_button_fms.banner-slider-fms:hover {
        display: none;
    }
    .slider_button_fms.fm_next {
        left: 98% !important;
    }
    .fm_cart {
        right: -8px !important;
    }
    // .fm_cart_compare {
    //     right: -10px !important;
    // }

    .compare_fm .img-model_fm {
        justify-content: space-around;
    }
    .info_fm {
        display: none;
    }
    .site__body {
        margin-bottom: 20px !important;
    }
    .info-attr_fm {
        margin: 10px !important;
    }
    .form-group.product__option .product__actions-item .input-number {
        margin: 0 !important;
    }
    .attr_label-fms {
        width: 180px !important;
    }
    .other-list__item {
        margin-right: 10px;
    }
    .product__prices > div {
        padding: 10px 20px;
    }
    .departments {
        width: auto !important;
        height: auto;
    }
    .mobile-header__logo {
        margin-left: 0 !important;
    }

    .search__input {
        font-size: 12px;
    }

    .search__button svg {
        width: 20px !important;
        height: 20px !important;
    }

    .search__form {
        border-bottom: 1px solid #ededed;
    }

    .indicator {
        margin: 0;
        // margin-left: 16px;
    }

    .nav-count-cont_fm {
        right: 0;
    }

    .topbar-dropdown__btn img {
        height: 24px;
        width: 24px;
    }

    .foot_content_fms {
        display: flex;
        flex-direction: column;
        // margin: 10px 0;
    }

    .site-footer img {
        margin: 8px 0;
    }

    .site-footer .container_fm {
        margin-top: 0;
    }

    .footer-newsletter__social-links {
        justify-content: start;
    }

    .footer-contacts {
        text-align: left;
    }

    .footer_contact_text_fms {
        font-size: 14px;
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid white;
        padding-top: 10px;
    }

    .foot_content_fms > div {
        margin: 0;
    }

    .footer-contacts__contacts li {
        margin: 0;
        padding: 8px 8px 0;
        font-size: 12px;
        color: white;
    }

    .footer-newsletter__social-links {
        margin-top: 0;
    }

    .cart_bank_fms {
        font-size: 12px;
        padding-top: 12px;
        padding-left: 8px;
    }

    .block-slideshow {
        margin-bottom: 20px;
    }
    .block-header__title__custom_fm {
        font-size: 16px;
        margin-bottom: 12px;
    }

    .product_fms {
        // max-width: 48%;
    }

    .product_fms {
        min-height: 294px;
        margin-bottom: 10px;
    }

    .product-card__name {
        font-size: 17px;
        margin-bottom: 10px;
    }

    .product-card__actions_fms {
        display: flex;
        align-items: center;
    }

    .product-card__prices {
        font-size: 14px;
        font-weight: normal;
    }

    .price_fms {
        font-size: 18px;
        font-weight: normal;
    }

    .product-card__addtocart_fms {
        justify-content: center;
        // width: 100%;
    }

    .product-card__addtocart_fms {
        // height: 34px;
        font-size: 16px;
    }

    .product-card__addtocart_fms svg {
        margin-right: 6px;
        margin-left: 6px;
        // width: 20px;
        // height: 15px;
    }

    .wish_fms {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        right: 0;
        z-index: 9;
    }

    .btn-svg-icon svg {
        width: 20px;
        height: 19px;
    }

    .del_fms {
        width: 100%;
        max-width: 100%;
    }

    .foot_bottom_fms > div {
        flex-direction: column;
        text-align: left;
        justify-content: center;
        align-items: flex-start;
    }

    .foot_bottom_fms > div * {
        font-size: 10px;
    }

    .view-options-sort_fm span {
        font-size: 16px;
    }

    .view-options-sort_fm span:first-child {
        // font-weight: 500;
    }

    .products-list__body {
        justify-content: space-between;
    }

    .compare_fm .img-model_fm {
        padding: 5px 0 10px 0;
    }

    .attr-title_fm {
        border-top: 2px solid #eeeeee;
        font-size: 12px;
    }

    .info-attr_fm {
        border-bottom: 2px solid #eeeeee;
        border-top: 0;
    }

    .compare_fm .img-model_fm > div:first-child {
        width: 30%;
    }

    .compare_fm .img-model_fm > div {
        width: 35%;
    }

    .attr-information_fm > div {
        font-size: 12px;
        width: 35%;
        word-wrap: break-word;
    }
    .attr-information_fm > div:first-child {
        width: 30%;
    }

    .compare-table__product-name {
        font-size: 16px !important;
    }

    .title_compare {
        font-size: 20px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .remove-compare_fm {
        right: 0;
    }

    .title_account_fms {
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .wishlist .product-card {
        min-height: 320px;
    }

    .wishlist > div {
        width: 48%;
        max-width: 48%;
    }

    .addresses-list__plus {
        width: 33px;
        height: 33px;
    }

    .save_profile_data_fm {
        font-size: 16px;
    }

    .container_fm {
        padding: 0px 8px;
        margin-top: 18px;
    }

    .cart_fms {
        margin-bottom: 50px;
    }

    .cart_block_fm .title_account_fms {
        margin-top: 11px;
    }

    .cart__update-button {
        padding: 10px 20px;
    }

    .cart__checkout-button {
        margin-left: 0 !important;
    }

    .cart_block_fm {
        padding: 8px 8px;
    }
    .cart-table__body {
        border: 1px solid #dcdee2 !important;
    }

    .cart__checkout-button {
        padding: 15px 10px;
        text-align: center;
        height: auto;
    }

    .cart__totals-footer {
        font-size: 18px;
        font-weight: 400;
        margin-top: 30px;
    }

    .cart__totals-footer > div > span {
        font-size: 18px;
        font-weight: 500;
    }
    .cart__actions {
        margin-top: 0;
    }

    .foot_content_fms > div {
        margin: 0;
    }

    .cart__buttons,
    .cart_go_back_fm {
        margin-top: 12px;
    }

    .cart_mob_title_fm > div {
        font-size: 18px;
        color: #000000;
    }

    .cart_mob_title_fm:last-child {
        margin-bottom: 12px;
    }

    .cart_mob_title_fm:first-child {
        margin-top: 12px;
    }

    .cart_mob_prod_fm {
        margin: 10px 0;
    }

    .site__body {
        padding-top: 0;
    }

    .page-header__breadcrumb {
        padding-top: 10px;
    }

    .cart_mob_prod_fm > div:last-child {
        font-size: 18px;
        color: #000000;
    }
    .input-number__input {
        width: 120px;
    }

    .cart__totals-footer div:last-child {
        margin-left: 10px;
    }

    // .card-title{
    //     font-size: ;
    // }

    .prod_fms .attr-information_fm > div {
        width: 50% !important;
    }

    .product--layout--standard {
        border: 1px solid #e4e4e4;
        padding-left: 12px;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-right: 12px;
    }
    .fm_cart {
        left: 34px;
    }
    .product__name {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .cash_title {
        font-size: 16px;
        font-weight: 400;
    }

    .prod_fms {
        margin-top: -30px;
    }

    .product__prices > span {
        font-size: 20px;
        font-weight: 500;
    }

    .product__prices .curency_fms {
        font-size: 16px;
    }

    .product__prices {
        // margin-bottom: 16px;
        font-size: 16px !important;
    }

    .product__meta {
        padding-top: 0;
        margin-top: 0;
    }

    // .product__meta > div > div{
    //     margin-bottom: 8px;
    // }

    // .product__meta > div{
    //     margin-bottom: 6px;
    // }

    .product_attributes .product__meta {
        margin-bottom: 0;
    }

    .topbar-dropdown__btn {
        padding-right: 0 !important;
        padding-left: 0 !important;
        padding-top: 10px;
    }

    .slider_button_fms {
        display: flex;
        left: 0px;
        padding: 0;
    }

    .partner_fms {
        // padding: 0 16px;
        position: relative;
    }

    .nav-panel {
        min-height: 40px;
    }

    .nav-panel__row {
        min-height: 50px;
    }

    .form-group.product__option .qty_actions-item {
        width: 100%;
        margin-bottom: 16px;
    }

    .form-group.product__option .product__actions-item .input-number {
        margin: 0 4px;
    }

    .product__options .add_cart_fms {
        padding: 10px 28px;
    }
}

@media (max-width: breakpoint(lg-start)) {
    .view-options__control_fm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .block-sidebar__item {
        padding: 20px;
    }

    .widget-filters {
        max-height: 350px;
        overflow-y: auto;
    }

    .widget__title {
        font-weight: 500;
    }

    .block-sidebar__header {
        border: none;
        height: auto;
        display: flex;
        justify-content: flex-end;
    }

    .block-sidebar__close {
        width: auto;
        height: auto;
        margin-top: 6px;
        border-left: 0 !important;
    }

    .nav-count-cont_fm {
        right: 0;
    }

    .account-nav ul {
        display: block !important;
        border: 1px solid #eeeeee;
    }

    .account-nav__item a {
        border: 0;
    }
}

#Icon_feather-list,
#Icon_feather-log-out {
    path {
        stroke: #222222;
    }
}
#user-2 {
    path {
        fill: #222222;
    }
}
.d-block_fm {
    display: inline-block;
}

.d-none_fm {
    display: none;
}

.rott_fms {
    transform: rotate(180deg);
}

.search-product_fm .product-card__prices {
    display: flex;
    // flex-direction: column;
}

.filter-list__list {
    max-height: 250px;
    overflow-y: auto;
}

.filter-list__list::-webkit-scrollbar {
    width: 5px;
}

.filter-list__list::-webkit-scrollbar-track {
    scrollbar-width: 1px;
    background-clip: content-box;
    background-color: #ebebeb;
    border: 4px solid transparent;
}

.filter-list__list::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    border-radius: 10px;
}

.menu_page_title_fm {
    font-size: 38px;
    font-weight: 400;
    color: #2d2d2d;
    text-align: center;
    margin-bottom: 32px;
}
.is-active > span {
    color: #f83641;
}

.cart-table__column--price > span {
    font-size: 21px;
}
.btn-primary:hover {
    background: #f83641 !important;
}

.add_cart_fms:hover {
    background: #f83641 !important;
    color: white;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.cart__checkout-button:hover {
    background: #f83641 !important;
}
.product-card__addtocart_fms:hover {
    background: #f83641;
}
// .btn-primary:active{
//     background:#3be3f9 !important;
// }
.btn-primary:visited {
    background: #f83641;
}

.attr_fms {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 14px !important;
    flex-direction: column;
}
.attr_color_fms {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.product__name {
    margin-bottom: 25px;
}

.attr_label-fms {
    max-width: 250px;
    width: 100%;
}
.other-attr_fm,
.color-attr_fm {
    width: 100%;
}
.product__meta {
    margin-bottom: 35px !important;
}
.other-list__item {
    max-width: 60px;
    /* width: 100%; */
    min-height: 20px;
    height: 100%;
}
.product__meta {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
.product__actions {
    // margin-bottom: 20px !important;
}
.product__actions {
    .title-button {
        flex-wrap: wrap;
        div {
            margin-bottom: 10px;
        }
    }
}
.mt-3,
.my-3 {
    margin-top: 30px !important;
}
.product__prices {
    margin-bottom: 0 im !important;
}

.product__actions-item-fms {
    display: flex;
    flex-wrap: wrap;
}

.product__actions-item--addtocart {
    margin-right: 24px;
}
.qty_actions-item {
    margin-right: 24px;
}
.info-attr_fm {
    margin: 0 30px 30px;
    margin-top: 30px;
}

.banner-fms {
    img {
        max-width: 100%;
        max-height: 500px;
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 100%;
        border-radius: 25px;
    }
}

.dropdown-group {
    display: none;
}

.dropdown-group.active {
    display: block;
}

.widget__title {
    cursor: pointer;
}
.cart__buttons {
    margin-bottom: 20px;
}

// border: $card-border;
//border-radius: $card-border-radius;
.checkout > .card {
    border: none !important;
}

.row .card {
    border: none !important;
}
.checkout > .container {
    border: $card-border;
    border-radius: $card-border-radius;
}

.form-row_column-row {
    flex-direction: column;
}

.form-row_column-row > div {
    max-width: none;
    width: 100%;
}

.cart-table__column--total > .price_fms {
    font-size: 20px !important;
}

.info_fm {
    display: none;
}
// .suggestions {
//     left: -8px !important;
// }
// .suggestions--location--header {
//     left: -8px !important;
// }
// .search__suggestions{
//     left: -8px !important;
// }

.is-pay-active {
    background-color: #3be3f9;
    border-radius: 2px;
    padding: 11px 46px;
    border: none;
    cursor: default !important;
    color: white;
}

.input-check__input {
    cursor: pointer;
}
.product-null-fms {
    min-height: 660px;
    height: 100%;
    max-width: 600px;
    width: 100%;
}

.list-null-fms {
    min-height: 800px;
    height: 100%;

    width: 100%;
}

.title-arrow-flex {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-bottom: 1px solid #e4e4e4;
}

// .suggestions__item-name:hover {
//     color: #3be3f9;
// }
// .filter-list__title:hover {
//     color: #3be3f9;
// }

.filter-list__title {
    padding: 5px 0 5px 5px;
}
.filter-list__title:hover {
    background: rgba(0, 0, 0, 0.05);
}

.suggestions__list {
    z-index: 2;
}
.is-active-filtre {
    color: re;
}

.idramform {
    display: none;
}
.social-links-items_fms {
    display: flex;
}

.amount:last-child {
    display: none;
}

.sales-fms > span {
    font-weight: 12px;
    color: white;
    margin-right: 3px;
}

.badges-fms {
    margin-left: auto;
    margin-bottom: 5px;
    position: absolute;
    right: 3px;
    z-index: 5;
}

.io {
    z-index: 1;
}

.slider-img-fms {
    width: 100% !important;
    margin-bottom: 10px !important;
    padding-right: 12px;
}

.shop-cat_fms {
    text-align: center;
}

.shop-cat_fms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

// .slick-list{
//  margin: 0 30px;

// }

.slider_button_fms > span {
    cursor: pointer;
}

.slider-span-fms {
    font-size: 14px !important;
    position: absolute;
    left: 0;
    width: 100%;
}
.slick-slide .slick-active {
    max-width: 140px !important;
    width: 100% !important;
    margin-left: 20px !important;
}

.shops-cat-fms > .partner_fms {
    .slider_button_fms {
        display: flex;
    }
}

.io {
    max-width: 60px;
    width: 100%;
    min-height: 20px;
}
.io > span {
    position: absolute;

    font-size: 14px;
    right: 0;
    padding-right: 3px;
}

.pointed {
    position: relative;
    width: 44px;
    height: 26px;
    padding: 4px;
    color: white;
    background-color: #e61236;
    display: flex;
    align-items: center;
    z-index: 4;
}

.pointed:after {
    position: absolute;
    left: -10px;
    top: 0px;
    content: "";
    width: auto;
    height: 26px;
    border-right: solid 10px #e61236;
    border-bottom: solid 10px transparent;
    border-top: solid 10px transparent;
}

.d-column-bage-fms {
    display: flex;
    flex-direction: column;
}

.badges-fms.pointed.io {
    right: 7px;
}

.singup_link_fm span {
    padding-left: 50px;
}

.login-error-fms {
    color: red !important;
    margin-top: 50px !important;
}

.topbar-dropdown__btn {
    padding-right: 0 !important;
}

.d {
    display: flex;
    flex-direction: column;
}

.slide_fms > .slider_button_fms {
    width: 100%;
}

.search-product_fm .product-card:hover {
    margin: 0 !important;
}

.badges-push-conteiner-fms {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.badges-fms {
    // position: relative;
    // right: -76%;
    position: absolute;
}

.badges-conteiner-fms {
    position: absolute;
    right: 0;
    width: 60px;
}

.sort-by-fms {
    display: flex;
    align-items: center;
}

.sort-by-fms > span {
    padding-right: 20px;
}

.sort_fms {
    display: flex;
}
.sort_fms > span {
    padding-right: 20px;
}
.sort-select-fms {
    border: none;
    outline: none !important;
    cursor: pointer;
    font-size: 15px;
    color: #3d464d;
}

․view-options-sort_fm {
    border: none;
    outline: none !important;
    cursor: pointer;
    font-size: 15px;
    color: #3d464d;
}

.sort-select-fms > option:hover {
    color: red;
}
.view-options-sort_fm {
    cursor: pointer;
}
.sort_fms > select:focus-visible {
    border: none;
}

.sort_fms > option {
    border: none;
}

.sort_fms > option:focus-visible {
    border: none;
}

.fm_cart {
    right: 6px;
    top: -6px;
}
.nav-count-cont_fm,
.fm_cart_compare {
    left: 73%;
    top: -5px;
}
.fm_cart_compare-fms {
    left: 80%;
    top: -5px;
}

.pay-adress-fms {
    margin: 0 !important;
}

.blog-news-fm {
    padding-top: 20px;
    font-size: 16px;
    height: 80px;
}

.nav-count_fm {
    display: flex;
    align-items: center;
    justify-content: center;
}

.d-icon {
    color: black;
    margin-left: 15px;
}
.indicator-log-in {
    .indicator__button {
        flex-direction: row;
        justify-content: space-between;
        padding-top: 10px;
        align-items: baseline;
    }
}

.category-containers-fms {
    display: flex;
    justify-content: space-between;
}
.category-hp-body {
    margin: 10px;
    z-index: 2;
    .category-hp-titles {
        color: white;
        padding-left: 30px;
        text-align: start;
    }
    .category-hp-titles p:first-child {
        color: #ffffff;
        font-size: 16;
        margin-bottom: 0;
    }
    .category-hp-titles p:nth-child(2) {
        color: #ffffff;
        font-size: 32;
        font-weight: 900;
    }
    .category-hp-titles p:last-child {
        font-size: 50px;
        font-weight: 900;
        line-height: 61px;
        letter-spacing: -1.3px;
        word-wrap: break-word;
    }
}
.first-fms {
    .category-hp-body:first-child {
        p {
            color: #ffffff !important;
        }
        p:nth-child(2) {
            font-size: 32px;
        }
        p:last-child {
            opacity: 0.36;
        }
    }
    .category-hp-body:nth-child(2) {
        p {
            color: #ffffff !important;
        }
        p:nth-child(2) {
            font-size: 32px;
        }
        p:last-child {
            opacity: 0.36;
            color: #ffffff !important;
        }
    }
    .category-hp-body:nth-child(3) {
        p {
            color: #ffffff !important;
        }
        p:nth-child(2) {
            font-size: 32px;
        }
        p:last-child {
            opacity: 0.36;
            color: #ffffff !important;
        }
    }
}
.second-fms {
    .category-hp-body:first-child {
        p {
            color: black;
        }
        p:nth-child(2) {
            font-size: 32px;
        }
        p:last-child {
            color: #ffffff !important;
        }
    }
    .category-hp-body:nth-child(2) {
        p {
            color: #ffffff !important;
        }
        p:nth-child(2) {
            font-size: 32px;
        }
        p:last-child {
            opacity: 0.36;
            color: #ffffff !important;
        }
    }
    .category-hp-body:nth-child(3) {
        p {
            color: #ffffff !important;
        }
        p:nth-child(2) {
            font-size: 32px;
        }
        p:last-child {
            opacity: 0.36;
            color: #ffffff !important;
        }
    }
}

.home-page-carusel {
    .block-products-carousel__slider {
        background: #fcfafb;
        padding: 30px;
        border-radius: 25px;
        .product-card__image {
            padding: 0 !important;
        }
        .product-card {
            background-color: #fcfafb !important;
        }
        .product-card::before {
            box-shadow: none !important;
        }
        .product-card__prices {
            // span {
            //     color: #6d6d6d !important;
            // }
        }
    }
    .product-card__addtocart_fms {
    }
}
.shop-castalog-titlerow {
    display: flex;
    align-items: center;
}

.childe-sub-fms {
    svg {
        margin-right: 20px;
    }
}
.search-button-icon {
    position: relative;
    border: none;
    background: none;
    padding-bottom: 10px;
    z-index: 55;
}
.title-button {
    display: flex;
    margin-bottom: 20px;
}

.btn-svg-icon svg {
    position: relative;
    left: 19px;
    top: 14px;
    transform: translate(-50%, -50%);
}
.calculator-titls {
    margin-top: 50px;
}
.calculator-container {
    padding: 10px;
    & > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.input-maney-fms {
    .col-md-6 {
        max-width: 100% !important;
        .form-control {
            border-radius: 50px;
        }
    }
}
.amd-monthly {
    padding-right: 10px;
}

.calculator-button-fms {
    button {
        margin: 0 auto;
        background: white;
        color: black;
        border: 2px solid #f83641;
        width: 90%;
    }
}

.banks-svg {
    svg {
        object-fit: contain;
        width: 100%;
    }
}

.description-buttons,
.specification-buttons {
    width: 50%;
    min-height: 40px;
    button {
        width: 100%;
        background: white;
        border: 1px solid #e4e4e4;
        height: 40px;
        border-radius: 10px;
        height: 100%;
        &:hover {
            background: #157dff;
        }
    }
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.specification-container {
    h1 {
        text-align: center;
        font-size: 26px;
        margin-bottom: 40px;
        margin-top: 20px;
    }
}

.info-buttnos {
    width: 73%;
}
.bottom-part-ofBanner {
    z-index: -1;
}
.active-button {
    background-color: yellow !important;
}

.first-block-banner-title {
    color: white;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.first-block-banner-titles {
    margin-left: 125px;
    margin-top: 45px;
    display: flex;
    font-size: 50px;
    font-weight: 900;
    line-height: 61px;
    letter-spacing: -1.3px;
}

.first-block-banner-content {
    margin-right: 125px;
    p {
        text-align: start;
        margin-bottom: 0;
    }
    p:nth-child(2) {
        font-size: 24px;
    }
    p:nth-child(3) {
        font-size: 48px;
    }
}

.ceeping-line-header {
    padding: 10px 0;
    background: #ededed;
    color: black;
    font-size: 16px;
    margin: 0px;
}

.block-banks-carousel__cell {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-height: 340px;
}
.block-banks-titles {
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
}

.block-banks-rate {
    display: flex;
    flex-direction: column;
    // border: 1px solid silver;
    padding: 5px 15px;
    height: 60px;
    top: -35px;
    span {
        display: none;
    }
}
.block-banks-carousel__cell:hover {
    .block-banks-rate {
        transition: 0.3s;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        span {
            display: flex;
            justify-content: end;
            padding-right: 5px;
        }
    }
}

.red-color-fms {
    color: red;
    padding-right: 5px;
}
.ip {
    .slick-track {
        position: relative;
        width: unset !important;
        top: 0;
        left: 0;
        display: flex !important;
        margin-left: unset !important;
        margin-right: unset !important;
        justify-content: space-between !important;
    }
}

.com-border-fms {
    border: 1px solid #f5f5f5;
    padding: 20px;
}
// .product-card__buttons {
//     width: 50px;
// }

.product-card__buttons:hover {
    position: relative;
    width: 120px;
    background: #f8979c;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s;
    .add-cart-button-fms {
        display: block;
        transition: 0.3s;
        margin-left: auto;
        color: white;
        white-space: nowrap;
        padding-left: 4px;
        // opacity: 0 !important;
    }
}
.add-cart-button-fms {
    display: none;
    white-space: nowrap;
}

.block-banks-rate::after {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 10px));
}

.thride-child-ms {
    span {
        color: black !important;
    }
}

.page-header__title-small {
    h1 {
        font-size: 14px;
        margin-bottom: 0;
    }
}
.block-products-carousel__column {
    cursor: pointer;
}
.block-products-carousel__column {
    .product-image__img {
        transform: scale(0.95);
    }
}
.block-products-carousel__column:hover {
    .product-image__img {
        transform: scale(1);
    }
}
.products-list__body {
    .price_fms,
    .add-cart-button-fms {
        font-size: 14px !important;
    }

    .add-cart-button-fms {
        margin-right: 2px !important;
    }
    .product-card__buttons > * + * {
        margin-left: auto !important;
    }
}

.btn-svg-icon:hover {
    svg {
        path {
            fill: #f83641 !important;
        }
    }
}
.wish_fms {
    .btn-light {
        border-radius: 50px;
        width: 36px !important;
    }
    svg {
        path {
            fill: black;
        }
    }
}

.bank-svg-fms {
    padding-left: 20px;
}

.banks-select {
    width: 100%;
    padding: 8px 12px;
    border-radius: 20px;
    border: 1px solid #d7d7d7;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
        no-repeat;
    background-position: right 15px top;
    -moz-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 7px 20px;
}

.nav-count_fm {
    .indicator__area {
        align-items: end !important;
        padding: 0 !important;
    }
}

.indicator-log-in {
    min-height: 64px;
}
.mobile-header__indicators {
    align-items: flex-end;
}
.banner-slider-fms {
    display: none !important;
}

.partner_fms {
    .slick-track {
        align-items: center !important;
    }
}

.amd-fms {
    margin-top: 30px;
    padding-left: 5px;
}

.rrrr {
    right: 50px;
}

.departments-all-titles {
    color: #000000;
    font-size: 14px;
    margin-left: 12px;
    margin-right: 10px;
    align-items: center;
    cursor: pointer;
    span {
        padding-right: 10px;
    }
}

.departments-all--hovver:hover {
    .departments__links {
        visibility: visible !important;
    }
}

.wish-list-count {
    top: -10px;
}

.inner-page-compare-fms {
    align-items: center;
    &:hover {
        color: #f83641;
        svg {
            path {
                fill: #f83641;
            }
        }
    }
    span:first-child {
        padding-right: 10px;
        &:hover {
            background: none;
        }
    }
}

.product__actions-item--compare {
    .btn-light:hover {
        background-color: white;
    }
}
.wish_and_fms {
    .btn-light:hover {
        background-color: white;
    }
}

.card-body {
    .payment-methods {
        margin-bottom: 0;
    }
}
.order-notes-fms {
    margin-top: 12px;
}

.form-control:focus {
    box-shadow: inset 0 0 2px #000000;
}
.slider-imges-fms {
    max-width: 200px;
}
.partner-img-fms {
    img {
        max-width: 200px;
    }
}

.prices-df-column {
    display: flex;
    flex-direction: column;
}

.footer_contact_text_fms {
    img {
        max-width: 200px;
    }
}

.banks-select {
    option:hover {
        background-color: red !important;
    }
}

.inner-page-fms {
    position: relative;
}

.product--layout-second-section {
    position: absolute;
    right: 0;
}
.departments__menu {
    .menu {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.mr-1em {
    margin-right: 1em;
}

.product__actions-item--wishlist {
    margin-right: 20px;
}

@media screen and(max-width: 447px) {
    .products-list__body {
        grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    }
}

.chek-fms {
    justify-content: center;
    align-items: center;
    svg {
        width: 31px;
        margin-right: 10px;
    }
}

.faild-toast-fms {
    justify-content: center;
    align-items: center;
    font-size: 15px;
    svg {
        margin-right: 10px;
        width: 31px;
    }
}
.badges-new-fms {
    z-index: 8;
    svg {
        margin-left: -11px;
    }
}

.badges-sold-fms {
    position: absolute;
    z-index: 8;
    svg {
        margin-left: -11px;
    }
}
.badges-sale-fms {
    margin-bottom: 5px;
}

.btn-save-new-pass {
    background: red;
    color: white;
    border-radius: 50px;
}
.alert-success-reset {
    .modalss {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        transition: 0.5s;
        pointer-events: none;
        transform: scale(0);
    }
    .modalss.active {
        opacity: 1;
        pointer-events: all;
        transform: scale(1);
        left: 0;
    }
    .modal-content {
        // padding: 20px;
        // border-radius: 12px;
        background-color: white;
        max-width: 1000px;
        height: auto;
        z-index: 101;
        transform: scale(0.5);
        transition: 0.4s all;
    }
    .modal-content.active {
        transform: scale((1));
        padding: 100px;
    }
    .alert-succes {
        .modalss.active {
            left: 0;
        }
        .modal-content {
            height: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .btn-secondary {
            width: 250px;
            margin: 30px auto;
            font-size: 22px;
        }
    }

    .modal-string {
        font-size: 20px;
        margin: 50px 0;
        text-align: center;
    }

    .modal-href {
        color: #1a66ff !important;
        font-size: 16px;
        text-decoration: revert;
        text-align: center;
    }
}

.modal-title {
    display: flex;
    justify-content: center;
    font-size: 32px;
    align-items: center;
    padding-bottom: 60px;
    border-bottom: 1px solid #e8e8e8;
    svg {
        height: 40px;
        width: 40px;
        margin-right: 20px;
    }
}

.activSvgFill {
    svg {
        path {
            fill: red;
        }
    }
}

.attr_fms-none {
    display: none;
}
