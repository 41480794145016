/*
// .block-banner
*/
@import "../variables";
@import "../functions";

.block-banner {
}
.block-banner__body {
    position: relative;
    display: block;
    text-align: center;
    color: inherit;
    max-height: 465px;
    &:hover {
        color: inherit;
    }
}

.block-banner__body img {
    max-width: 100%;
}

.block-banner__image {
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}
.block-banner__image--mobile {
    display: none;
}
.block-banner__title {
    padding-top: 28px;
    font-size: 30px;
    line-height: 1;
    font-weight: $font-weight-bold;
}
.block-banner__text {
    padding-top: 8px;
}
.block-banner__button {
    padding-top: 20px;
}
.block-banner__mobile-br {
    display: none;
}

.first-block-banner-fms {
    .first-block-banner {
        position: relative;
        display: flex;
        justify-content: center;
        min-height: 540px;
        height: 100%;
        bottom: 305px;
        img {
            position: absolute;
        }
    }

    .first-block-banner {
    }
    min-height: 540px;
    height: 100%;
}

@media (max-width: breakpoint(sm-end)) {
    .block-banner__body {
        // height: 390px;
    }
    .block-banner__image--desktop {
        display: none;
    }
    .block-banner__image--mobile {
        display: block;
    }
    .block-banner__title,
    .block-banner__text,
    .block-banner__button {
        width: 220px;
        margin-left: auto;
        margin-right: auto;
    }
    .block-banner__title {
        padding-top: 48px;
        line-height: 1.1;
    }
    .block-banner__text {
        padding-top: 12px;
    }
    .block-banner__button {
        padding-top: 28px;
    }
    .block-banner__mobile-br {
        display: block;
    }
}
