/*
// .block-brands
*/
@import "../variables";
@import "../functions";
@import "../mixins/card";

.block-brands {
}
.block-brands__slider {
    @include card();

    .slick-slide > div > div {
        vertical-align: middle;

        &:focus {
            outline: none;
        }
    }
}
.block-brands__item {
    img {
        max-width: 100%;
        max-height: 400px;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}
